import {
  submitMsStatusResi, dataEditMsStatusResi, deleteMsStatusResi, comissionUseCase
} from '@/domain/usecase'
import {
  ref, getCurrentInstance, reactive, onMounted
} from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import DialogSearchPiIcon from '@/views/shared-components/dialog/DialogSearchPiIcon.vue'

export default {
  components: {
    DialogSearchPiIcon
  },
  props: {
    id: {
      default: () => ''
    }
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, { emit } :any) {
    const app = getCurrentInstance()
    const title = ref('Tambah Status Resi')
    const isActive = ref(false)
    const CanChange = ref(false)
    const submitted = ref(false)
    const isShowSearchIcon = ref(false)
    const ket = ref('')
    const Warna = ref('000000')
    const id = ref(props.id)
    const store = useStore()
    const dataForm = reactive({
      Kode: '',
      Status: '',
      Icon: '',
    })

    const rules = {
      Kode: {
        required: helpers.withMessage('Kode harus diisi', required)
      },
      Status: {
        required: helpers.withMessage('Status harus diisi', required)
      },
      Icon: {
        required: helpers.withMessage('Icon harus diisi', required)
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties

    const getDataEdit = async (idTipe: any) => {
      const response = await dataEditMsStatusResi(idTipe)
      if (!response.error) {
        dataForm.Kode = response.result.Kode
        dataForm.Status = response.result.Status
        dataForm.Icon = response.result.Icon
        isActive.value = response.result.IsActive
        // CanChange.value = response.result.CanChange
        Warna.value = response.result.Warna
      }
    }

    if (id.value) {
      title.value = 'Edit Status Resi'
      getDataEdit(id.value)
    }

    const checkErorResponse = (response: any) => {
      if (!response.error) {
        $toast.add({
          severity: 'success',
          detail: $strInd.toast.successSubmit,
          group: 'bc',
          life: 1500
        })
        emit('reloadData')
      } else {
        store.dispatch('hideLoading')
        $toast.add({
          severity: 'error', message: response.result.detail, duration: 1500
        })
      }
    }

    const submitProccess = async () => {
      store.dispatch('showLoading')
      const data = {
        Id: id.value,
        Kode: dataForm.Kode.toUpperCase(),
        Status: dataForm.Status,
        Icon: dataForm.Icon,
        IsActive: isActive.value,
        CanChange: CanChange.value,
        Warna: Warna.value.includes('#') ? Warna.value : `#${Warna.value}`,
      }
      console.log('data', data)
      const response = await submitMsStatusResi(data)
      checkErorResponse(response)
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }

    const deleteData = (e:any) => {
      $confirm.require({
        header: 'Status Resi',
        message: 'Apakah anda yakin ingin menghapusnya?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await deleteMsStatusResi(id.value)
          checkErorResponse(response)
        }
      })
    }

    const getIcon = (val: any) => {
      dataForm.Icon = val
      isShowSearchIcon.value = false
    }
    const closeSidebar = () => {
      emit('closeSidebar')
    }

    return {
      isActive,
      CanChange,
      title,
      id,
      ket,
      Warna,
      submitted,
      deleteData,
      submitData,
      dataForm,
      v$,
      closeSidebar,
      isShowSearchIcon,
      getIcon
    }
  }
}
